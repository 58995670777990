import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, useSortBy, useFilters } from 'react-table';
import './App.css';

// Filtro simple
function DefaultColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value || undefined)}
      placeholder={`Buscar...`}
    />
  );
}

function App() {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.get('/api/accounts');
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();
  }, []);

  // Definir las columnas
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name', // Llave del objeto account
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Account Number',
        accessor: 'accountnumber',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Revenue',
        accessor: 'revenue',
        Filter: DefaultColumnFilter,
      },
    ],
    []
  );

  // Definir los datos de la tabla
  const data = useMemo(() => accounts, [accounts]);

  // Usar la función `useTable` de React Table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,  // Habilitar filtrado
    useSortBy    // Habilitar ordenación
  );

  return (
    <div className="container">
      <h1>Accounts from Dynamics 365</h1>
      <table {...getTableProps()} className="accounts-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Añadir iconos para indicar ordenación */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                  {/* Renderizar el filtro */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default App;
